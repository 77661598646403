import React from "react"
import ReactPlayer from "react-player/youtube"
import PlayIcon from "../../assets/svg/PlatyIcon.svg"

const VideoPlayer = ({ url }) => {
  const handleOnReady = () => {}
  return (
    <>
      <div className="player-wrapper">
        <ReactPlayer
          light
          width={"100%"}
          height={280}
          onReady={handleOnReady}
          url={url}
          playIcon={<PlayIcon />}
        />
      </div>
    </>
  )
}

export default VideoPlayer
