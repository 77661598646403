import React from "react"
import styled from "styled-components"
import { FlexContainer } from "../../../styles/layout.styled"
import { useInView } from "react-intersection-observer"
import Image from "gatsby-image"

const TeamCardContainer = styled(FlexContainer)`
  flex-direction: column;
  transform: ${({ inView }) => (inView ? "scale(1)" : "scale(0)")};
  transition: all 0.3s ease;
  margin-bottom: 10px;
  width: 306px;
  @media only screen and (max-width: ${({ theme }) => theme.tablet}) {
    margin-bottom: 30px;
    width: 306px;
  }
  @media only screen and (max-width: ${({ theme }) => theme.mobile}) {
    width: 100%;
  }
`

const ImageContainer = styled(FlexContainer)`
  background: transparent;
  cursor: pointer;
  width: 100%;
  height: 306px;
  border-radius: 8px;
  @media only screen and (max-width: ${({ theme }) => theme.mobile}) {
    height: 100%;
  }
`

const Name = styled.h6`
  color: ${({ theme }) => theme.lightBlue};
  font-weight: bold;
  font-size: 18px;
  margin: 16px 0 12px;
`

const Position = styled.span`
  color: ${({ theme }) => theme.lightBlue};
  font-weight: 100;
  font-size: 14px;
  margin: 0;
`

const TeamCard = ({ member, trigger }) => {
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
    initialInView: false,
  })

  const {
    id,
    employeeName,
    position,
    employeePicture: { fluid },
  } = member

  // const handleOnClick = memberDetails => {
  //   setTrigger(true)
  //   setDetails(prev => ({ ...prev, ...memberDetails }))
  // }

  return (
    <TeamCardContainer ref={ref} inView={inView}>
      <ImageContainer onClick={() => (trigger ? null : null)}>
        <Image
          fluid={fluid}
          key={id}
          loading="auto"
          style={{ height: "100%", width: "100%" }}
        />
      </ImageContainer>

      <Name>{employeeName.toUpperCase()}</Name>
      <Position>{position.toUpperCase()}</Position>
    </TeamCardContainer>
  )
}

export default TeamCard
