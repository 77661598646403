import React from "react"
import styled from "styled-components"
import { Autoplay, Navigation, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import { FlexContainer } from "../../../styles/layout.styled"
import AmStart from "../../../assets/images/companyLogos/AM-start-Adrine-Mkrtchyan.png"
import Austrian from "../../../assets/images/companyLogos/Austrian-Red-Cross.png"
import EasyTouch from "../../../assets/images/companyLogos/easyTouch.png"
import FortArena from "../../../assets/images/companyLogos/FortArena.png"
import Iris from "../../../assets/images/companyLogos/iris.png"
import KidsExpert from "../../../assets/images/companyLogos/kids-expert.png"
import KidsFashionDay from "../../../assets/images/companyLogos/Kids-fashion-day.png"
import MTwoShop from "../../../assets/images/companyLogos/M2-shop.png"

const logos = [
  AmStart,
  Austrian,
  EasyTouch,
  Iris,
  KidsExpert,
  KidsFashionDay,
  MTwoShop,
  FortArena,
]

const StyledBg = styled.img`
  width: 180px;
  height: 100px;
  aspect-ratio: 1;
`

const CompanyLogosContainer = styled(FlexContainer)`
  max-width: 1440px;
  margin: 50px auto 150px;
`
const CompanyLogos = () => {
  return (
    <CompanyLogosContainer>
      <Swiper
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        modules={[Pagination, Navigation, Autoplay]}
        navigation={{
          prevEl: null,
          nextEl: null,
        }}
        loop
        breakpoints={{
          100: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          700: {
            slidesPerView: 4,
            spaceBetween: 10,
          },
          1440: {
            slidesPerView: 6,
            spaceBetween: 47,
          },
        }}
      >
        {logos.map((src, index) => (
          <SwiperSlide
            key={index}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <StyledBg src={src} />
          </SwiperSlide>
        ))}
      </Swiper>
    </CompanyLogosContainer>
  )
}

export default CompanyLogos
