import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { FlexContainer } from "../../../styles/layout.styled"
import CountUp from "react-countup"
import { useInView } from "react-intersection-observer"

const Number = styled.span`
  font-size: 54px;
  font-weight: bold;
  color: ${({ theme }) => theme.white};
  margin-bottom: 8px;
  @media only screen and (max-width: ${({ theme }) => theme.mobile}) {
    font-size: 28px;
  }
`

const Title = styled.h4`
  font-size: 14px;
  font-weight: 100;
  color: ${({ theme }) => theme.white};
  margin: 0;
  @media only screen and (max-width: ${({ theme }) => theme.mobile}) {
    font-size: 10px;
  }
`

const Counter = ({ value, title }) => {
  const [start, updateStart] = useState(0)
  const [end, updateEnd] = useState(0)
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
    initialInView: false,
  })

  useEffect(() => {
    if (inView) {
      updateStart(prev => prev)
      updateEnd(+value)
    } else {
      updateEnd(0)
    }
  }, [value, inView])

  return (
    <FlexContainer flexDirection="column" ref={ref}>
      <CountUp
        start={start}
        end={end}
        delay={0}
        duration={2}
        children={({ countUpRef }) => <Number ref={countUpRef} />}
      />
      <Title>{title}</Title>
    </FlexContainer>
  )
}

export default Counter
