import React from "react"
import styled from "styled-components"
import { useInView } from "react-intersection-observer"
import { FlexContainer } from "../../../styles/layout.styled"
import Background from "../../reusable/Background"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"

const H4 = styled.h4`
  font-size: 22px;
  font-weight: 100;
  color: ${({ theme }) => theme.white};
  text-align: center;
  margin: 0 0 16px;
  opacity: ${({ inView }) => (inView ? "1" : "0")};
  transform: ${({ inView }) =>
    inView ? "scale(1) translateY(0)" : "scale(0) translateY(60px)"};
  transition: all 0.9s ease;
`

const H1 = styled.h1`
  font-size: 62px;
  font-weight: bold;
  color: ${({ theme }) => theme.white};
  text-align: center;
  width: 50%;
  margin: 0;
  opacity: ${({ inView }) => (inView ? "1" : "0")};
  transform: ${({ inView }) =>
    inView ? "rotate(0) translateY(0)" : "rotate(-45deg) translateY(-500px)"};
  transition: all 0.9s ease;
  @media only screen and (max-width: ${({ theme }) => theme.tablet}) {
    width: 100%;
    font-size: 32px;
    transform: ${({ inView }) =>
      inView ? "rotate(0) translateY(0)" : "rotate(-45deg) translateY(-600px)"};
  }
  @media only screen and (max-width: ${({ theme }) => theme.mobile}) {
    transform: ${({ inView }) =>
      inView ? "rotate(0) translateX(0)" : "rotate(-45deg) translateX(-100px)"};
  }
`

const ImageContainer = styled.div`
  width: 516px;
  height: 386px;
  transform: ${({ inView }) =>
    inView ? "translateX(0)" : "translateX(-440px)"};
  opacity: ${({ inView }) => (inView ? "1" : "0")};
  transition: all 1s ease;
  @media only screen and (max-width: ${({ theme }) => theme.tablet}) {
    margin-bottom: 20px;
    width: 100%;
    transform: ${({ inView }) =>
      inView ? "translateX(0)" : "translateX(-200px)"};
  }
  @media only screen and (max-width: ${({ theme }) => theme.mobile}) {
    width: 100%;
  }
`

const CaptionContainer = styled(FlexContainer)`
  transform: ${({ inView }) =>
    inView ? "translateX(0)" : "translateX(440px)"};
  opacity: ${({ inView }) => (inView ? "1" : "0")};
  transition: all 1s ease;
  @media only screen and (max-width: ${({ theme }) => theme.tablet}) {
    transform: ${({ inView }) =>
      inView ? "translateX(0)" : "translateX(200px)"};
    align-items: center;
  }
`

const H5 = styled.h5`
  font-size: 24px;
  font-weight: bold;
  color: ${({ theme }) => theme.white};
  text-align: left;
  margin: 0 0 26px;
  @media only screen and (max-width: ${({ theme }) => theme.mobile}) {
    font-size: 18px;
  }
`

const Caption = styled.p`
  color: ${({ theme }) => `${theme.white}88`};
  font-size: 18px;
  margin: ${({ margin }) => margin || 0};
  font-weight: 100;
  @media only screen and (max-width: ${({ theme }) => theme.tablet}) {
    text-align: center;
  }
  @media only screen and (max-width: ${({ theme }) => theme.mobile}) {
    font-size: 14px;
  }
`

const OurTeamContainer = styled(FlexContainer)`
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1100px;
  padding: 42px 40px;
  @media only screen and (max-width: ${({ theme }) => theme.tablet}) {
    flex-direction: column;
  }
  @media only screen and (max-width: ${({ theme }) => theme.mobile}) {
    width: 100%;
    padding: 42px 20px;
  }
`

const SectionOne = () => {
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
    initialInView: false,
  })
  const { ref: titleRef, inView: titleInView } = useInView({
    threshold: 0,
    triggerOnce: true,
    initialInView: false,
  })
  const { ref: imageRef, inView: imageInView } = useInView({
    threshold: 0,
    triggerOnce: true,
    initialInView: false,
  })
  const { ref: captionRef, inView: captionInView } = useInView({
    threshold: 0,
    triggerOnce: true,
    initialInView: false,
  })

  const { ourCompany, aboutUs } = useStaticQuery(graphql`
    {
      ourCompany: contentfulWebsiteImages(title: { eq: "our company" }) {
        image {
          fluid(quality: 100) {
            src
            base64
            aspectRatio
            srcSet
            sizes
          }
        }
      }
      aboutUs: contentfulWebsiteImages(title: { eq: "aboutUsBg" }) {
        image {
          fluid(quality: 100) {
            src
            base64
            aspectRatio
            srcSet
            sizes
          }
        }
      }
    }
  `)
  return (
    <>
      <Background height="730px" withColumns fluid={aboutUs.image.fluid}>
        <FlexContainer
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <H4 ref={titleRef} inView={titleInView}>
            ABOUT US
          </H4>
          <H1 ref={ref} inView={inView}>
            WE ARE MEG PRODUCTION
          </H1>
        </FlexContainer>
      </Background>
      <OurTeamContainer padding="46px 0">
        <ImageContainer ref={imageRef} inView={imageInView}>
          <Image
            fluid={ourCompany.image.fluid}
            loading="auto"
            style={{ height: "100%", width: "100%", borderRadius: "8px" }}
          />
        </ImageContainer>

        <CaptionContainer
          tabletWidth="100%"
          width="40%"
          alignItems="flex-start"
          flexDirection="column"
          ref={captionRef}
          inView={captionInView}
        >
          <H5>OUR COMPANY</H5>
          <Caption margin="0 0 10px">
            We are a team of dreamers, a team making our dreams real. Due to our
            diligence and purposefulness, we created MEG Production on
            12.10.2020, which has been prospering day by day. <br /> Till
            Covid-19 expands its map and endangers people’s lives, we had to be
            closed at home like you but these days didn’t pass fruitless. <br />{" "}
            We use that time to improve our knowledge and become more
            professional.
          </Caption>
          <Caption>
            We obtain professional technique and due to all that we can say
            surely “TRUST US” since we are ready to promote your company
            activity development. <br />
            We always highlight our consumers using <br /> pro-customers
            solutions and our goal is to make such a production, which will help
            our customers to stand in a competitive market.
          </Caption>
        </CaptionContainer>
      </OurTeamContainer>
    </>
  )
}

export default SectionOne
